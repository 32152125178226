var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import CalendarDropdown from '@declaration/components/appointment/CalendarDropdown.vue';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { longDateDay } from '@declaration/services/appointmentLabels';
import { formatTime } from '@shared/utils/date';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { INSURER_CONFIG } from '@shared/insurer';
const claim = namespace('claim');
const defaultIllustration = 'documents/checklist';
const defaultInlineLayout = false;
const defaultParagraphs = [
    ['appointment', 'mail'],
    ['expert', 'calendar-2020'],
    ['documents', 'file'],
    ['caseNumber'],
];
let ExpertAppointmentConfirmation = class ExpertAppointmentConfirmation extends Vue {
    get appointmentConfig() {
        var _a;
        return (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.expertAppointment;
    }
    get illustration() {
        var _a, _b;
        return (_b = (_a = this.appointmentConfig) === null || _a === void 0 ? void 0 : _a.illustration) !== null && _b !== void 0 ? _b : defaultIllustration;
    }
    get paragraphs() {
        var _a, _b;
        return (_b = (_a = this.appointmentConfig) === null || _a === void 0 ? void 0 : _a.paragraphs) !== null && _b !== void 0 ? _b : defaultParagraphs;
    }
    get inlineLayout() {
        var _a, _b;
        return (_b = (_a = this.appointmentConfig) === null || _a === void 0 ? void 0 : _a.inline) !== null && _b !== void 0 ? _b : defaultInlineLayout;
    }
    get contents() {
        return this.paragraphs.map(([key, icon]) => ({
            icon,
            html: this.$tc(`appointment.expert.confirmation.description.${key}`, undefined, this.appointmentInfo),
        }));
    }
    get appointmentInfo() {
        return {
            day: longDateDay(this.claim.expertAppointment.startDateTime, this.$tc.bind(this)).toLowerCase(),
            startHour: formatTime(this.claim.expertAppointment.startDateTime, true),
            endHour: formatTime(this.claim.expertAppointment.endDateTime, true),
            expertCompanyName: this.claim.expertAppointment.expertSociety.name,
            caseNumber: this.claim.caseNumber,
        };
    }
    get insurerHasFeatureNoBackHomeButton() {
        return insurerHasFeature(InsurerFeatureFlag.NoBackHomeButton);
    }
};
__decorate([
    claim.State('claim')
], ExpertAppointmentConfirmation.prototype, "claim", void 0);
ExpertAppointmentConfirmation = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
            ParagraphsWithIcon,
            CalendarDropdown,
        },
        props: {
            step: {
                type: String,
                required: true,
            },
        },
    })
], ExpertAppointmentConfirmation);
export default ExpertAppointmentConfirmation;
